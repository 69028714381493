import React from 'react';
import {useFormikContext} from 'formik';
import styled from 'styled-components';

import TypeTitle from 'Project/TypeTitle/TypeTitle';
import {onPropertyChange} from 'Design/Design';
import CopyValuesBtn from 'Project/Top/CopyValuesBtn';
import {InputNumber} from 'components/Input';

import {InputsContainer} from '../../Project';
import { useTranslation } from 'react-i18next';

const Container = styled.div`
  margin: 0 0 2rem 0;
`;

const TopGate = () => {
  const formik = useFormikContext();
  const { t } = useTranslation();

  if (!formik.values.gate.chosen) {
    return (
      <Container>
        <TypeTitle type="gate"/>
      </Container>
    )
  }

  return (
    <>
      <Container>
        <TypeTitle type="gate"/>
      </Container>
      {formik.values.gate.gateType && formik.values.gate.gateType !== 'nonSymmetricalGate' && (
        <>
          <InputsContainer style={{marginTop: '-1.65rem'}}>
            <CopyValuesBtn type="gate"/>
            <div className="form-group">
              <InputNumber
                change={(v) => onPropertyChange(formik, 'gate', 'width', v)}
                val={formik.values.gate.widthInit}
                info={formik.touched.gate?.width && formik.errors.gate?.width}
                label={t('gateWidth')}
                name="gate.width"
                onBlur={formik.handleBlur}
              />
              {formik.values.gate.width > 0 && (
                <b style={{ color: "red" }}>{t('replacedVal')} {formik.values.gate.width} mm</b>
              )}
              {formik.values.gate.width > 5999 && (
                <b style={{ color: "red" }}>{t('gateBig')}</b>
              )}
            </div>
            <div className="form-group">
              <InputNumber
                change={(v) => onPropertyChange(formik, 'gate', 'height', v)}
                val={formik.values.gate.heightInit}
                info={formik.touched.gate?.height && formik.errors.gate?.height}
                label={t('gateHeight')}
                name="gate.height"
                onBlur={formik.handleBlur}
              />
              {formik.values.gate.height > 0 && (
                <b style={{ color: "red" }}>{t('replacedVal')} {formik.values.gate.height} mm</b>
              )}
            </div>
          </InputsContainer>
        </>
      )}
      {formik.values.gate.gateType && formik.values.gate.gateType === 'nonSymmetricalGate' && (
        <>
          <InputsContainer style={{marginTop: '-1.65rem'}}>
            <CopyValuesBtn type="gate"/>
            <div className="form-group">
              <InputNumber
                change={(v) => onPropertyChange(formik, 'gate', 'height', v)}
                val={formik.values.gate.heightInit}
                info={formik.touched.gate?.height && formik.errors.gate?.height}
                label={t('gateHeight')}
                name="gate.height"
                onBlur={formik.handleBlur}
              />
              {formik.values.gate.height > 0 && (
                <b style={{ color: "red" }}>{t('replacedVal')}: {formik.values.gate.height} mm</b>
              )}
            </div>
            <div className="form-group">
              <InputNumber
                change={(v) => onPropertyChange(formik, 'gate', 'leftWingWidth', v)}
                val={formik.values.gate.leftWingWidth}
                info={formik.touched.gate?.leftWingWidth && formik.errors.gate?.leftWingWidth}
                label={t('leftWingWidthInput')}
                name="gate.leftWingWidth"
                onBlur={formik.handleBlur}
              />
            </div>
            <div className="form-group">
              <InputNumber
                change={(v) => onPropertyChange(formik, 'gate', 'rightWingWidth', v)}
                val={formik.values.gate.rightWingWidth}
                info={formik.touched.gate?.rightWingWidth && formik.errors.gate?.rightWingWidth}
                label={t('rightWingWidthInput')}
                name="gate.rightWingWidth"
                onBlur={formik.handleBlur}
              />
            </div>
          </InputsContainer>
        </>
      )}
    </>
  );
};

export default TopGate;
